.ReactModal__Overlay {
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  padding: 20px;
  z-index: 2000;
  background-color: rgba(0,0,0,0.5) !important;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

.ReactModal__Content {
  text-align: left;
}

.ReactModal__Content--custom {
  min-width: 300px;
  width: unset;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  outline: none !important;
  max-width: 100%;
}

.close-btn{
  position:absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  padding: 5px;
  font-size: 20px;
  
  &:hover {
    opacity: .75;
  }
}
.modal-title{
  h4{
    font-size: 38px;
    font-weight: 700;
  }
}

.ReactModal__Content--custom2 {
  width: unset;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  //padding: 40px;
  border-radius: 5px;
  outline: none !important;

  .close-btn{
    color: #fff;
    top: -40px;
    right: -40px;
    font-size: 30px;
  }
}

.form-modal{
  width: 500px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.success-request{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffff;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: .3s;
  visibility: hidden;
}

.success-request-visible{
  opacity: 1;
  visibility: visible;
}

.video-modal{
  iframe, video{
    width: 900px;
    height: 450px;
  }
}

@media(max-width: 767px){
    .ReactModal__Content--custom{
        padding: 20px;
    }
    .form-modal{
        width: 100%;
        .modal-title h4{
            font-size: 22px;
        }
    }
}